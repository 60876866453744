<template>
  <div class="ym-page">

    <!-- 列表组件 -->
    <div class="table-group">
      <ym-table-list
        :headData="headData"
        :data="tableData"
        :columns="columns"
        :tableOption="{size: 'mini'}"
        :loading="loading"
        fit
        stripe
        row-key="controlID"
        :tree-props="{children: 'child'}"
        :expand-row-keys="menuTypeArray"
        >
        <!-- 使用列表插槽 -->
        <template #status="{row}">
          {{row.status | cnStatus}}
        </template>
        <template #action="{row}">
          {{row.action ? row.action : row.remark}}
        </template>
      </ym-table-list>
    </div>

    <!-- 新增表单 -->
    <el-dialog class="tableList-addForm" width="800px" :title="dialogTitle" :visible.sync="dialogShow" :close-on-click-modal="false">
      <ymForm label-position="left"
        ref="addForm"
        refCode="addForm"
        :formValue="dialogValue"
        :formData="dialogForm"
        @onSubmit="onSubmit">
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button v-show="dialogForm[0]&&dialogForm[0].showbutton" size="medium" @click="submitForm('addForm')" type="primary">提交 </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import ymForm from '@/components/YmForm'
import tableObj from './data.js'
export default {
  components: {
    YmTableList,
    ymForm
  },
  data() {
    return {
      loading: false,
      formValue: {},
      dialogTitle: '',
      dialogShow: false,
      dialogValue: {},
      dialogForm: [],
      tableObj: tableObj,
      columns: tableObj.columnsData,
      addForm: tableObj.addForm,
      formData: tableObj.formData,
      headData: tableObj.headData,
      tableData: [],
      menuTypeArray: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 获取表格数据
    async getData() {
      this.loading = true
      const { data, code } = await this.$api.rbac.listControls()
      // 处理树结构
      if (code === 200) {
        // 处理树结构
        var tree = []
        for (let node of data) {
          var array = []
          for (let leaf of data) {
            if (node.controlID === leaf.parentID) {
              array.push(leaf)
            }
          }
          node.child = array
          if (!node.parentID && node.controlID) {
            tree.push(node)
            this.tableData = tree
          }
        }
        // 获取类型等零的菜单
        data.forEach(item => {
          if (!item.type) {
            this.menuTypeArray.push(item.controlID)
          }
        })
      }
      this.loading = false
    },
    // 重置表单
    resetForm(key) {
      this.$set(this, key, {})
      this.getData()
    },
    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    // 提交事件
    async onSubmit(form, ref) {
      let res = await this.$api.rbac.addControl(form)
      if (res.code === 200) {
        this.dialogShow = false
        this.dialogValue = {}
        this.getData()
        this.$message.success('添加数据成功')
      } else {
        this.$message.error(res.message)
      }
    },
    // 删除
    deleteRow(row) {
      let thisVue = this
      this.$confirm('此操作将永久删除该条记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await thisVue.$api.rbac.deleteControl(row)
        if (res.code === 200) {
          thisVue.getData()
          thisVue.$message.success('删除成功')
        } else {
          thisVue.$message.error(res.message)
        }
      })
    }
  },
  filters: {
    cnStatus(value) {
      return value === 1 ? '正常' : '拉黑'
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-input {
  width: 84%;
}
.tableList-addForm{
  /deep/.el-dialog{
    max-width: 600px;
    .el-dialog__body{
      padding: 30px 80px 50px;
      .tableList-form-buttonBox{
        text-align: center;
      }
    }
    .el-select {
      width: 480px;

    }
    .passwordContent{
      margin: 20px 0 50px 0;
    }
  }
}
/deep/.tableList-addForm .el-dialog .el-dialog__body .tableList-form-buttonBox {
    text-align: right;
    margin-top: 16px;
}
</style>
