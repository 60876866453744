import { activeArray } from '@/utils/localStorage.js'

// 新增表单配置
let addForm = [{
  showbutton: true,
  slotArr: ['roleTree'],
  items: {
    title: {
      label: '权限名称',
      type: 'text',
      placeholder: '请输入',
      maxLength: 20,
      clearable: true,
      rules: [{
        required: true,
        message: '请输入权限名称'
      },
      {
        min: 2,
        max: 20,
        message: '长度在 2 到 20 个字符'
      }
      ]
    },
    action: {
      label: '控制器',
      type: 'text',
      placeholder: '请输入',
      clearable: true,
      rules: [{
        required: true,
        message: '请输入控制器'
      }]
    },
    type: {
      label: '类型',
      type: 'select',
      clearable: true,
      children: [{
        value: '0',
        label: '导航菜单'
      },
      {
        value: '1',
        label: '功能接口'
      }
      ]
    }
  }
}]

// 编辑表单配置
let editForm = [{
  showbutton: true,
  slotArr: ['roleTree'],
  items: {
    title: {
      label: '权限名称',
      type: 'text',
      rules: [{
        required: true,
        message: '请输入活动名称'
      },
      {
        min: 2,
        max: 20,
        message: '长度在 2 到 20 个字符'
      }
      ]
    },
    action: {
      label: '控制器',
      type: 'text',
      rules: [{
        required: true,
        message: '请输入控制器'
      }]
    },
    type: {
      label: '类型',
      type: 'select',
      children: [{
        value: '0',
        label: '导航菜单'
      },
      {
        value: '1',
        label: '功能接口'
      }
      ]
    }
  }
}]

// 表头图标配置
let headData = {
  title: '权限列表',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'el-icon-plus',
      size: 'medium',
      type: 'primary',
      // 控制按钮显示隐藏
      showFilter: (item, thisVue) => {
        return activeArray('/auth/auth-add-control')
      },
      clickEvent: (thisVue) => {
        thisVue.dialogForm = thisVue.tableObj.addForm
        thisVue.dialogValue = {
          type: '0'
        }
        thisVue.dialogTitle = '添加权限'
        // 清除点编辑之后清空表单出现的校验提示
        if (thisVue.$refs.addForm) {
          thisVue.$refs.addForm.$refs.form.resetFields()
        }
        thisVue.$set(thisVue, 'dialogShow', true)
      }
    }
  ]
}

// 列表columns配置
let columnsData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 100
  },
  {
    label: '权限名称',
    prop: 'title',
    show: true
  },
  {
    label: '控制器',
    prop: 'action',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    width: 180,
    align: 'right',
    fixed: 'right',
    children: {
      edit: {
        label: '添加',
        icon: 'ym-icon-tianjia',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/auth/auth-add-control')
        },
        clickEvent: (thisVue, row) => {
          thisVue.dialogForm = thisVue.tableObj.addForm
          thisVue.dialogValue = {
            type: '1'
          }
          if (row.controlID) {
            thisVue.dialogValue.parentID = row.controlID
          }

          thisVue.dialogTitle = '添加 ' + row.title
          thisVue.$set(thisVue, 'dialogShow', true)
        }
      },
      delete: {
        label: '删除',
        icon: 'ym-icon-shanchu',
        color: '#FF8851',
        // 控制按钮显示隐藏
        showFilter: (item, row, thisVue) => {
          return activeArray('/auth/auth-delete-control')
        },
        clickEvent: (thisVue, row) => {
          thisVue.deleteRow(row)
        }
      }
    }
  }]

let tableObj = {
  'columnsData': columnsData, // 表格列/操作列配置
  'headData': headData, // 表头图标配置
  'addForm': addForm, // 新增表单配置
  'editForm': editForm // 编辑表单配置
}

export default tableObj
